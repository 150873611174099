.center_children {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;
  scrollbar-width: 'none';
 -ms-overflow-style: 'none';
}

.fix-icon {
  vertical-align: .13em !important;
}

.min-h-screen {
  min-height: 100vh;
}

.no-seleccionable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}

.primary-button {
  padding: 20px 35px;
  align-items: center;
  display: inline-flex;
  border-radius: 5px;
}

.primary-button:hover > span {
  text-decoration: underline;
}

.text-button {
  background-color: transparent !important;
  font-weight: bold;
}

.text-button:hover > span {
  text-decoration: underline;
}

.content-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@primary-color: #e23e00;@layout-header-background: #262626;